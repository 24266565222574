import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

const models = {
    okapi: new URL('models/okapi.glb', import.meta.url),
    micchan: new URL('models/micchan.glb', import.meta.url),
    nakapo: new URL('models/nakapo.glb', import.meta.url),
    aikun: new URL('models/aikun.glb', import.meta.url),
    parfait: new URL('models/parfait.glb', import.meta.url)
};

const loader = new GLTFLoader();
loader.setMeshoptDecoder( MeshoptDecoder );

const ua = window.navigator.userAgent;
const badWebKit = ua && (ua.match(/Version\/15\.4.* Safari/i) || ua.match(/CPU (iPhone )?OS 15_4[0-9-_]* like Mac OS X/));

function getHorizontalFov(camera) {
    let fov = THREE.MathUtils.degToRad(camera.fov)
    return 2 * Math.atan( camera.aspect * Math.tan(fov / 2));
}

function loadParfait(scene) {
    return new Promise( (resolve, reject) => {
        const url = models['parfait'];
        loader.load(
            url.href,
            gltf => {
                scene.add( gltf.scene );
                resolve();
            },
            undefined,
            e => reject(e)
        );
    });
}

function loadSideMode(scene, camera) {
    const characters = ['okapi', 'micchan', 'nakapo', 'aikun'];
    const promises = characters.map((character) => {
        let model;
        const url = models[character];

        return new Promise( (resolve, reject) => {
            loader.load(
                url.href,
                gltf => {
                    model = gltf.scene;

                    const element = document.getElementById(character);
                    const updateProgress = () => {
                        const position = window.pageYOffset + (window.innerHeight * 0.5);
                        const elementTop = element.offsetTop;
                        progress = (position - elementTop) / element.clientHeight;
                        progress = 2 * (progress - 0.5); // -1 to +1
                    }

                    const updateModelMatrix = () => {
                        // hide models that are not going to be on screen
                        if (progress <= -1.0 || progress >= 1.0) {
                            model.visible = false;
                            return;
                        } else {
                            model.visible = true;
                        }

                        const distance = 8;
                        const charWidth = 0.7; // how far off screen
                        const peekAngle = Math.PI / 3.5; // angle to tilt into the page

                        // position just outside the viewport, looking at camera
                        let angle = getHorizontalFov(camera) / 2 + charWidth / distance;
                        model.position.x = -distance * Math.sin(angle);
                        if (side == 'right') {
                            model.position.x *= -1;
                        }
                        model.position.z = -distance * Math.cos(angle);
                        model.setRotationFromAxisAngle(
                            new THREE.Vector3(0, 1, 0),
                            (side == 'right' ? -1 : 1) * angle
                        );

                        // ... -1 -> 0 -> +1... => +1 -> 0 -> +1
                        let p = Math.min(Math.abs(progress), 1);

                        // sine of [0 -> 180deg (pi/2) -> 0] to smooth motion
                        p = Math.sin(Math.PI / 2 * (1 - p));
                        model.rotateZ(
                            peekAngle * (side == 'right' ? 1 : -1) * p
                        );
                    }

                    const side = characters.indexOf(character) % 2 == 1 ? 'left' : 'right';
                    let progress = -1;
                    updateProgress();
                    updateModelMatrix();
                    scene.add( model );

                    window.addEventListener('resize', () => {
                        updateProgress();
                        updateModelMatrix();
                    });

                    window.addEventListener('scroll', () => {
                        updateProgress();
                        updateModelMatrix();
                    });

                    resolve();
                },
                undefined,
                e => reject(e)
            );
        });
    });

    return Promise.all(promises);
}

document.querySelectorAll('.model-container').forEach( (container) => {
    const clock = new THREE.Clock();

    let isLoaded = false;

    const renderer = new THREE.WebGLRenderer( { antialias: !badWebKit, alpha: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( container.clientWidth, container.clientHeight );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMappingExposure = 1;
    container.appendChild( renderer.domElement );

    const scene = new THREE.Scene();

    {
        const color = 0xFFFFFF;
        const intensity = 0.9;
        const light = new THREE.AmbientLight(color, intensity);
        scene.add(light);
    }

    let directionalLight;
    {
        const color = 0xFFFFFF;
        const intensity = 0.4;
        directionalLight = new THREE.DirectionalLight(color, intensity);
        scene.add(directionalLight);
    }

    const camera = new THREE.PerspectiveCamera( 40, container.clientWidth / container.clientHeight, 1, 100 );

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.target.set( 0, 1, 0 );
    controls.addEventListener('change', (e) => {
        directionalLight.position.set(
            camera.position.x,
            camera.position.y,
            camera.position.z,
        );
    });
    controls.update();
    controls.minPolarAngle = Math.PI * 0.45;
    controls.maxPolarAngle = Math.PI * 0.45;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.enableZoom = false;

    const animate = () => {
        // const delta = clock.getDelta();
        // model.rotateY(delta * 0.6);

        controls.update();

        renderer.render( scene, camera );

        if (!document.hasFocus()) return;
        requestAnimationFrame( animate );
    }

    const run = () => {
        isLoaded = true;
        animate();
    }

    if (container.dataset.mode == 'parfait') {
        const cameraDistance = 2.6;
        camera.position.set( 0, 0, cameraDistance );
        controls.autoRotate = true;
        controls.autoRotateSpeed = -5;
        loadParfait(scene).then(run);
    }

    if (container.dataset.mode == 'side') {
        camera.position.set(0, 1, 0);
        camera.lookAt(0, 0, -1);
        loadSideMode(scene, camera).then(run);
    }

    window.addEventListener('resize', () => {
        renderer.setSize( container.clientWidth, container.clientHeight );
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();
    });

    window.addEventListener('focus', () => {
        if (isLoaded) animate();
    });
});
